import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';


const messages = defineMessages({
  besteversaving: {
    id: 'hitcard.besteversaving',
    defaultMessage: 'Best Ever Saving',
  },
  lastchance: {
    id: 'hitcard.lastchance',
    defaultMessage: 'Last Chance',
  },
  preorder: {
    id: 'hitcard.preorder',
    defaultMessage: 'Pre-order',
  },
  preorderwithdate: {
    id: 'hitcard.preorderwithdate',
    defaultMessage: 'Releases {date}',
  },
  earlyaccess: {
    id: 'hitcard.earlyaccess',
    defaultMessage: 'Available {date}',
  },
  earlyaccessnow: {
    id: 'hitcard.earlyaccessnow',
    defaultMessage: 'Available Now',
  },
  newrelease: {
    id: 'hitcard.newrelease',
    defaultMessage: 'Released {date}',
  },
  flashdeal: {
    id: 'hitcard.flashdeal',
    defaultMessage: 'Flash Deal',
  },
  stardeal: {
    id: 'hitcard.stardeal',
    defaultMessage: 'Star Deal',
  },
  justlaunched: {
    id: 'hitcard.justlaunched',
    defaultMessage: 'Just Launched',
  },
  eBookBundle: {
    id: 'hitcard.eBookBundle',
    defaultMessage: 'eBook Bundle',
  },
  inLibrary: {
    id: 'hitcard.inLibrary',
    defaultMessage: 'In Library',
  },
  inSteamLibrary: {
    id: 'hitcard.inSteamLibrary',
    defaultMessage: 'In Steam Library',
  },
  bundlefest: {
    id: 'hitcard.bundlefest',
    defaultMessage: 'Bundlefest',
  },
  lowstock: {
    id: 'hitcard.lowstock',
    defaultMessage: 'Low Stock',
  },
});

export default function HitCardLabel({
  slug,
  bestDiscount,
  preorderDate,
  startingDate,
  endingDate,
  type,
  displayType,
  presale,
  discountPercent,
  flashDealCard,
  noReleaseDate,
  systemDiscount,
  labelOverride,
  earlyAccessDate,
  isStarDeal,
}) {
  const { formatMessage, formatDate } = useIntl();

  const timeSinceLaunch = (Date.now() / 1000) - startingDate;

  let lastChance = false;
  if (flashDealCard) {
    const dealSecondsRemaining = endingDate - (Date.parse(new Date()) / 1000);
    if (dealSecondsRemaining < 14400) lastChance = true;
  }

  const startDate = '2024-05-20T15:00:00.000Z';
  const endDate = '2024-05-27T15:00:00.000Z';
  let bundlefestLive = false;
  if (Date.now() > Date.parse(startDate) && Date.now() < Date.parse(endDate)) {
    bundlefestLive = true;
  }

  let label = null;
  let name = null;
  let showPreorderDate = false;

  if (lastChance) {
    label = 'lastchance';
    name = formatMessage(messages.lastchance);
  }

  if (bestDiscount) {
    label = 'bestdiscount';
    name = formatMessage(messages.besteversaving);
  }

  if ((preorderDate * 1000) > Date.now() && !presale && !earlyAccessDate) {
    label = 'preorder';
    name = formatMessage(messages.preorder);

    if (!noReleaseDate) {
      showPreorderDate = true;
      name = formatMessage(messages.preorderwithdate, {
        date: formatDate(new Date(preorderDate * 1000), { month: 'short', day: '2-digit' }),
      });
    }
  }

  if ((preorderDate * 1000) > Date.now() && earlyAccessDate) {
    label = 'earlyaccess';
    name = formatMessage(messages.earlyaccessnow);

    if ((earlyAccessDate * 1000) > Date.now()) {
      name = formatMessage(messages.earlyaccess, {
        date: formatDate(new Date(earlyAccessDate * 1000), { month: 'short', day: '2-digit' }),
      });
    }
  }

  if ((type === 'pick-and-mix' || type === 'bundle') && bundlefestLive) {
    label = 'bundlefest';
    name = formatMessage(messages.bundlefest);
  }

  if (
    !flashDealCard &&
    discountPercent > 0 &&
    (endingDate - startingDate < 176400) &&
    !systemDiscount
  ) {
    label = 'flashdeal';
    name = formatMessage(messages.flashdeal);
  }

  if (!flashDealCard && (displayType && displayType.includes('bundle')) && timeSinceLaunch < 86400 && label !== 'flashdeal') {
    label = 'landed';
    name = formatMessage(messages.justlaunched);
  }

  if (isStarDeal) {
    label = 'stardeal';
    name = formatMessage(messages.stardeal);
  }

  if (labelOverride === 'newrelease') {
    label = 'preorder';
    showPreorderDate = true;
    name = formatMessage(messages.newrelease, {
      date: formatDate(new Date(preorderDate * 1000), { month: 'short', day: '2-digit' }),
    });
  }

  if (labelOverride === 'inLibrary') {
    label = 'inLibrary';
    name = formatMessage(messages.inLibrary);
  }

  if (labelOverride === 'inSteamLibrary') {
    label = 'inSteamLibrary';
    name = formatMessage(messages.inSteamLibrary);
  }

  if (slug === 'safe-in-our-world-charity-bundle-2024') {
    label = 'lowstock';
    name = formatMessage(messages.lowstock);
  }

  return (
    <React.Fragment>
      {label &&
        <div className={classNames('hitCardLabel', { 'pre-order-date': showPreorderDate })}>
          <div className={`d-flex label-${label}-container`}>
            <div className="label-content">
              {label === 'inLibrary' &&
                <FontAwesomeIcon icon={faBookOpen} className="mr-1" />
              }
              {label === 'inSteamLibrary' &&
                <img
                  src="https://cdn.fanatical.com/production/icons/drm/light/steam-icon.svg"
                  alt="Steam Logo"
                  className="d-inline svg-inline--fa fa-lg mr-1"
                />
              }
              {name}
            </div>
            <div className="label-slash" />
          </div>
          <div className={`label-triangle triangle-${label}`} />
        </div>
      }
    </React.Fragment>
  );
}

HitCardLabel.propTypes = {
  slug: PropTypes.string,
  bestDiscount: PropTypes.bool,
  preorderDate: PropTypes.number,
  earlyAccessDate: PropTypes.number,
  startingDate: PropTypes.number,
  endingDate: PropTypes.number,
  type: PropTypes.string,
  displayType: PropTypes.string,
  presale: PropTypes.bool,
  flashDealCard: PropTypes.bool,
  discountPercent: PropTypes.number,
  noReleaseDate: PropTypes.bool,
  labelOverride: PropTypes.string,
  systemDiscount: PropTypes.bool,
  isStarDeal: PropTypes.bool,
};

HitCardLabel.defaultProps = {
  slug: '',
  bestDiscount: false,
  preorderDate: 0,
  earlyAccessDate: 0,
  startingDate: 0,
  endingDate: 0,
  type: '',
  displayType: '',
  presale: false,
  flashDealCard: false,
  discountPercent: 0,
  noReleaseDate: false,
  collections: [],
  pathname: '',
  labelOverride: '',
  systemDiscount: false,
  isStarDeal: false,
};
